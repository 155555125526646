import React from "react";
import Slider from "react-slick";
import Testimonial2 from "../../../assets/Images/Testimonial/Testimonial2.png";
import DefaultCustomer from "../../../assets/Images/Testimonial/default.png";

function PeopleSay() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 1500,
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="spacing">
        <div className="peopleSayBgColor">
          <div className="container">
            <div className="titleFont text-center">
              <h3>What People Say</h3>
              <h2>What Our Client Said</h2>
            </div>
            <Slider {...settings}>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial2}
                />
                <div className="peopleDetails">
                  <p>
                    Ensure Business Solutions provide us a great support on our sales & inventory maintenance. 
                    They have done many customizations according to our requirement. System is very accurate & user friendly.
                  </p>
                  <h3>Yasarathna Bandara</h3>
                  <h6>Proprietor - UK Fire Safety</h6>
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={DefaultCustomer}
                />
                <div className="peopleDetails">
                  <p>
                    "Ensure" team worked hard to deliver the software solution we needed. They have visited our head office and gave us the necessary trainings. We are willing to move to their web based inventory management system very soon!
                  </p>
                  <h3>Sunith Liyanapathirana</h3>
                  <h6>Director - Universal Hardware</h6>
                </div>
              </div>
              {/* <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial4}
                />
                <div className="peopleDetails">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the standard
                    dummy text ever since the 1500s, when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book.
                  </p>
                  <h3>Ramisha Goenka</h3>
                  <h6>Web Designer</h6>
                </div>
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeopleSay;
