import React from 'react';
import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import EnsureLogo from "../../assets/Images/BrandLogo/EnsureLogo.png";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  const [Aopen, AsetOpen] = useState(false);

  return (
    <>
      <div className="footer">
        <Container>
          <Row className="Footerbox">
            {/* <Col xl={5} lg={5} md={4} sm={12}>
              <div className="F-col-1 footer-text">
                <img
                  src={EnsureLogo}
                  alt="EnsureLogo"
                  className="img-fluid mx-auto WLogo"
                />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the dummy text ever
                  since the 1500s.Lorem Ipsum is simply dummy text of the
                  printing.
                </p>
              </div>
            </Col> */}
            {/* <Col xl={2} lg={2} md={2}>
              <div className="F-col-2 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Useful Links
                  <Button
                    className="Footer-button"
                    onClick={() => UsesetOpen(!Useopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Useopen}
                  ></Button>
                </h3>
                <Collapse in={Useopen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                      <Link to="/planing">Price List</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Case stories</Link>
                    </li>
                    <li>
                      <Link to="/aboutus">Latest news</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col> */}
            {/* <Col xl={2} lg={2} md={2}>
              <div className="F-col-3 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Services
                  <Button
                    className="Footer-button"
                    onClick={() => CsetOpen(!Copen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Copen}
                  ></Button>
                </h3>
                <Collapse in={Copen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/service">Help</Link>
                    </li>
                    <li>
                      <Link to="/faq">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/planing">Terms of use</Link>
                    </li>
                    <li>
                      <Link to="/contact">Support</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col> */}
            <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading">Athurugiriya
                <p>(Head Office)</p>
                <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button></h3>
                  <Collapse in={Aopen}>
                <div className="quickContact">
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>
                      20/10, Araliya Mawatha, Athurugiriya
                    </span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>+94 719 002 630</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>hello@ensure.lk</span>
                  </div>
                </div>
                </Collapse>
              </div>
            </Col>
          
          
            <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading">Kandy
                <p>(Development & Support Center)</p>
                <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button></h3>
                  <Collapse in={Aopen}>
                <div className="quickContact">
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>
                      87, Fairland Estate, Uda Eriyagama, Peradeniya
                    </span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>+94 766 738 729</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>chaturanga@ensure.lk</span>
                  </div>
                </div>
                </Collapse>
              </div>
            </Col>

            <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading"> Kaluthara
                <p>(Development & Support Center)</p>
                <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button></h3>
                  <Collapse in={Aopen}>
                <div className="quickContact">
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>
                      137/7, Aralaiya Mw., Melegama, Wadduwa.
                    </span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>+94 770 547 722</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>rukshan@ensure.lk</span>
                  </div>
                </div>
                </Collapse>
              </div>
            </Col>

            <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading">Kottawa
                <p>(Support Center)</p>
                <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button></h3>
                  <Collapse in={Aopen}>
                <div className="quickContact">
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>
                    188/2B, Horana Road, Kottawa, Pannipitiya
                    </span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>+94 719 351 370</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>lakmal@ensure.lk</span>
                  </div>
                </div>
                </Collapse>

              </div>
            </Col>
          </Row>

          <Row>
          <Col className=' social-icon'>
            <ul className="list-unstyled">
                  <li className="d-inline-block">
                    <a href="https://www.facebook.com/Ensure.lk/" target="_blank" className="d-block">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://wa.me/94766738729?text=Hello%2C%20I%27m%20interested%20about%20your%20business%20solutions%21" target="_blank" className="d-block">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  {/* <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li> */}
                </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <h6 className="F-copywrite">Copyright © 2022 by Ensure Business Solutions (Pvt) Ltd.</h6>
      </div>
    </>
  );
};
export default Footer;
