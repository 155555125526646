import blog1 from "../../../assets/Images/Blog/blog1.png";
import blog2 from "../../../assets/Images/Blog/blog2.png";
import blog3 from "../../../assets/Images/Blog/blog3.png";
import blog4 from "../../../assets/Images/Blog/blog4.png";
import blog5 from "../../../assets/Images/Blog/blog5.png";
export const OurBlogData= [
    {
        id: 1,
        image: blog1,
        date: "14 March 2022",
        profile:"By Admin",
        title: "Secure active living depend son repair day ladies now.",              
        contain: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since to 00s, when an unknown printer."   
      },
      {
        id: 2,
        image: blog2,
        date: "14 March 2022",
        profile:"By Admin",
        title: "Secure active living depend son repair day ladies now.",              
        contain: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since to 00s, when an unknown printer."   
      },
      {
        id: 3,
        image: blog3,
        date: "14 March 2022",
        profile:"By Admin",
        title: "Secure active living depend son repair day ladies now.",              
        contain: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since to 00s, when an unknown printer."   
      },
      {
        id: 4,
        image: blog4,
        date: "14 March 2022",
        profile:"By Admin",
        title: "Secure active living depend son repair day ladies now.",              
        contain: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since to 00s, when an unknown printer."   
      },
      {
        id: 5,
        image: blog5,
        date: "14 March 2022",
        profile:"By Admin",
        title: "Secure active living depend son repair day ladies now.",              
        contain: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since to 00s, when an unknown printer."   
      },
 
];
export default OurBlogData;