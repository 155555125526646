
import blogcontain1 from "../../../assets/Images/Blog/blogcontain1.jpg";


export const BlogData= [
    {
        id: 1,
        image: blogcontain1,
        date: "14 March 2022",
        comments:"251 comments",
        like: "596 likes",
        title: "Post Title Here",              
        contain: "Lorem ipsum dolor sit amet, consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti "   
      },
     
 
];
export default BlogData;