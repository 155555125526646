import React from "react";
import "../../../../src/theme/css/HomePage.css";
import Banner from "../../../assets/Images/Banner/Banner.jpg";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <div className="mainBannerDiv">
        <img className="img-fluid mx-auto" src={Banner} alt="BannerImage" />
        <div className="container">
          <div className="bannerContain text-center" data-aos="zoom-in">
            <h1>Smartest way to manage your business</h1>
            <h5>
              <b>A simple solution to handle your complex business easily!</b> 
               Whether you’re running a single location or multiple location business, 
              our cloud-based POS software will provide you the smart & easy solution to ENSURE your business run smoothly.
            </h5>
            <button className="button">
              <Link to="/contact">Get Started </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
