import React, { useEffect } from "react";
import aboutus from "../../../assets/Images/AboutUs/aboutus.jpg";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
export default function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="aboutus spacing">
          <Row className="aboutRow">
            <Col xl={6} lg={6} md={6} sm={5} xs={12}>
              <img
                className="img-fluid mx-auto"
                alt="AboutUs"
                src={aboutus}
                data-aos="flip-down"
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={7} xs={12} data-aos="fade-up">
              <div className="titleFont">
                <h3>About Our Company</h3>
                {/* <h2>A team of highly motivated, innovative & experienced Professionals, 
                  who have years of experience in designing, development & implementation of business solutions</h2> */}
                  <h2>
                    Thriving your business is our business
                  </h2>
              </div>
              <p className="paragraph">
                Keep your business thriving towards success with Ensure Business Solutions - the best software solution provider to ensure smooth and flawless operations of your business. 
              </p>
              <div className="aboutUsPoint">
                <Row>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Powerful and easy</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>24/7 Support</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Friendly Support Service</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>100% Secured</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Professional Team</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Highly Customizable</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <button className="button">
                <Link to="/aboutus">More About Us </Link>
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
