import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Icon1 from "../../../assets/Images/WeProvide/Icon1.png";
import Icon2 from "../../../assets/Images/WeProvide/Icon2.png";
import Icon3 from "../../../assets/Images/WeProvide/Icon3.png";
import Icon4 from "../../../assets/Images/WeProvide/Icon4.png";
import Icon5 from "../../../assets/Images/WeProvide/Icon5.png";
import Icon6 from "../../../assets/Images/WeProvide/Icon6.png";
import Aos from "aos";
import "aos/dist/aos.css";
export default function WeProvide() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="weProvide spacing">
          <Row className="weProvideRow">
            <Col xl={12} lg={9} md={9} sm={9} xs={12}>
              <div className="titleFont">
                <h3>What we Provide</h3>
                <h2>We ENSURE to provide the best out of the technology!</h2>
              </div>
            </Col>
            {/* <Col
              xl={2}
              lg={3}
              md={3}
              sm={3}
              xs={12}
              className="weProvideBtnCol"
            >
              <button className="button">
                <Link to="/service">All Services </Link>
              </button>
            </Col> */}
          </Row>
          <Row data-aos="zoom-in">
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon1}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>POS & Inventory Management</h6>
                  <p className="paragraph">
                    Managing a retail/wholesale business won't be easy without a proper POS & Inventory Management System.
                    We will provide you the best solution, just to match your business processes!
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon2}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Web Development</h6>
                  <p className="paragraph">
                    Connecting your business to the internet is like opening your store to the whole universe! 
                    We can give you the most creative & innovative web presence to enhance your business locally & globally!
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon3}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Graphic Design</h6>
                  <p className="paragraph">
                    We believe telling things through branding will make people love your services. Combining art & technology is what we love to do.
                    Our creative designers will give you the best art for your business branding!
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon4}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Accounting</h6>
                  <p className="paragraph">
                    Good records will help you to Ensure the business growth! 
                    Our experienced and qualified accountants will make sure your business accounts are operating perfectly!
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon5}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>App Development</h6>
                  <p className="paragraph">
                    Letting your customers to connect with your own mobile app is the new trend! Managing your business using
                    a mobile device is smart & time effective. We can provide both!
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon6}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Customized Development</h6>
                  <p className="paragraph">
                    Every business is unique. That uniqueness will bring the great success of the business. We'd love talk with you about your business requirement.
                    We will give you a tailor-made solution!
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
