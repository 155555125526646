
export const OurPricingData= [
  {
    id: 1,
    title: "Starter",
    newPrice: "$99",
    oldPrice: "$120",
    subTitle: "Per Month",
    contain1: "5 Pages (Dynamic Website)",
    contain2: "10 Email IDs ",
    contain3: "Google Analytics Integration",
    contain4: "Social Media Sharing Ontion",
    contain5: "Hosing (1 year Free)",
    contain6: "Domain (1 Year Free)"    
  },
  {
    id: 2,
    title: "Standard",
    newPrice: "$120",
    oldPrice: "$140",
    subTitle: "Per Month",
    contain1: "8-10 Pages (Dynamic Website)",
    contain2: "10 Email IDs ",
    contain3: "Google Analytics Integration",
    contain4: "Social Media Sharing Ontion",
    contain5: "Hosing (1 year Free)",
    contain6: "Domain (1 Year Free)"   
  },
  {
    id: 3,
    title: "Enhanced",
    newPrice: "$200",
    oldPrice: "$250",
    subTitle: "Per Month",
    contain1: "Shopping Cart, PAyment Gateway",
    contain2: "10 Email IDs ",
    contain3: "Google Analytics Integration",
    contain4: "Social Media Sharing Ontion",
    contain5: "Hosing (1 year Free)",
    contain6: "Domain (1 Year Free)"  
  },
 
];
export default OurPricingData;