import React, { useEffect, useState  } from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'

export default function ContactUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

   const navigate = useNavigate();
   const [validationError,setValidationError] = useState({})
  const [state, setState] = React.useState({
		name: '',
		email: '',
		company: '',
		phone: '',
		message: '',
	});

  const [emailError, setemailError] = useState(null);

	const handleChange = (event) => {

		setState({ ...state, [event.target.name]: event.target.value });
	};
  
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const SaveMessage = async (e) => {
    e.preventDefault();
    if (!isValidEmail(state.email)) {
        Swal.fire({
          confirmButtonColor:"#73def9",
          icon:"error",
          text:"Invalid email address"
        })
      } else {
        const formData = new FormData()

        formData.append('Name', state.name)
        formData.append('Email', state.email)
        formData.append('Company', state.company)
        formData.append('Phone', state.phone)
        formData.append('Message', state.message)

        const temp_data = {};
        formData.forEach((value, key) => (temp_data[key] = value));

        await axios.post('/web-api/public/api/v1/contact', temp_data).then(({data})=>{
          Swal.fire({
            confirmButtonColor:"#73def9",
            icon:data.icon,
            text:data.message
          })
          navigate("/")
        }).catch(({response})=>{
          if(response.status===422){
            setValidationError(response.data.errors)
          }else{
            Swal.fire({
              confirmButtonColor:"#73def9",
              text:response.data.message,
              icon:"error"
            })
          }
        })
      }
  }


  return (
    <>
      <div className="aboutUsBannerImg">
        <div className="container">
          <div className="contactUs">
            <Row>
              <Col xl={12} md={12} lg={12} sm={12}>
                <div>
                <div className="titleFont">
                  <h3>Let's Contact Us</h3>
                  <h2>Ready to Get Started?</h2>
                </div>
                <form className="row g-3 needs-validation formContactus" onSubmit={SaveMessage}>
                  <div className="col-md-6  col-sm-6 col-6">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      value={state.name}
                      placeholder="Name*"
                      required
                    />
                  </div>

                  <div className="col-md-6 col-sm-6 col-6">
                    <input
                      type="text"
                      className="form-control"
                      id="company"
                      name="company"
                      onChange={handleChange}
                      value={state.company}
                      placeholder="Company Name*"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a valid Company Name.
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-6">     
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={state.email}
                      placeholder="Email*"
                      required
                    />
                  </div>

                  <div className="col-md-6 col-sm-6 col-6">     
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      onChange={handleChange}
                      value={state.phone}
                      placeholder="Phone*"
                      required
                    />
                  </div>


                  <div className="col-md-12">
                    <textarea
                      type="text"
                      className="form-control"
                      id="message"
                      name="message"
                      onChange={handleChange}
                      value={state.message}
                      placeholder="Send Meassage*"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a Send Meassage.
                    </div>
                  </div>

                  <div className="col-12">
                    <button className="button">Send</button>
                  </div>
                </form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
