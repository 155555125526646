import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import AboutMenuBlog from "../../../assets/Images/AboutUs/AboutMenuBlog.png";
import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";
import AboutUsVideo from "../AboutUs/AboutUsVideo";
import aboutus from "../../../assets/Images/AboutUs/aboutuuslid1.jpg";
import aboutusImg1 from "../../../assets/Images/AboutUs/aboutusImg1.jpg";
import aboutusImg3 from "../../../assets/Images/AboutUs/aboutusImg3.jpg";
import aboutusImg4 from "../../../assets/Images/AboutUs/aboutusImg4.jpg";
import OurTeam from "../HomePage/OurTeam";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";
import Brandlogo from "../HomePage/Brandlogo";
export default function AboutUsMenu() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const settings = {
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="otherPageBanner contactUsBanner">
        <div className="text-center title">
          <h2>About Us</h2>
          <h6>We are specialize in "Solutions"!</h6>
        </div>
      </div>
      <div className="container">
        <div className="aboutMenu ">
          <Row>
            <Col xl={6} lg={6} md={6} sm={7}>
              <Slider {...settings}>
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutus}
                />
                {/* <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutusImg1}
                /> */}
                {/* <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutusImg3}
                />
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutusImg4}
                /> */}
              </Slider>
            </Col>
            <Col xl={6} lg={6} md={6} sm={5} className="colForResponsive">
              <div className="titleFont">
                {/* <h3>ABOUT BUSINESS</h3> */}
                <h2>We make your business easy!</h2>
              </div>
              <p>We are a software company based in Sri Lanka that develops Enterprise systems to grow the retail business both locally and internationally. Our head office is based in Colombo - Sri Lanka. All the software designs and developments are done by well-experienced and tech enthusiastic software engineers, and professionals using the latest technologies. </p>
              <p>Our team operates with enthusiasm and flexibility. We are focused on our customers' business requirements and being their reliable partner for IT services and solutions, working together on a partnership basis, helping them to face the business challenges of the modern times, using the best IT solutions. </p>
  
              
              {/* <button className="button">
                <Link to="/service">Explore Solutions </Link>
              </button> */}
            </Col>
          </Row>
        </div>

        {/* <div className="aboutusMenuCounting">
          <Row>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>8M+</h2>
              <h6>Trusted user</h6>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>64K</h2>
              <h6>Telephonic talk</h6>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>9M+</h2>
              <h6>Project completed</h6>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>250</h2>
              <h6>Award winning</h6>
            </Col>
          </Row>
        </div> */}

        {/* <AboutUsVideo /> */}
      
      <span>
      <p>We are offering fully-fledged services for software development and engineering empowering the businesses of our clients. Our main products\Services are :</p>
        <ul>
          <li>Point Of Sales & Inventory Management</li>
          <li>Web development</li>
          <li>Mobile Application development</li>
          <li>Software service/development outsourcing</li>
          <li>Retail business consultation</li>
          <li>Accounting & Audit consultation</li>
        </ul>
      <p>Our success comes with three main principles we always follow:</p>
      <ul>
        <li>True collaboration with customers and partners</li>
        <li>Complete understanding of customers' business/requirements</li>
        <li>Persistence in finishing the job within deadlines, whatever it takes!</li>
      </ul>
      <p>We also have special expertise in custom software development - providing specialized products, IT services, and custom end-to-end solutions to our enterprise customers. 
        We gain a competitive advantage from these distinctive capabilities and have developed the ability to implement and manage complex IT systems in changing times with greater effectiveness than many competitors. 
        We offer reasonable Service Level Agreements (SLA) covering most of the additional maintenance services. With our 24/7/365 days per year support, we are making troubleshooting as easy as possible.</p>
      <h4>What is our Mission:</h4>
      <p>Ensure Business Solutions' mission is to enhance the business operations of its clients and ensure the business growth, data security, and providing necessary data/analytics by developing and/or implementing IT products and services that business owners and users ever wanted by :</p>
      <ul>
        <li>Providing high-quality software development services, professional consulting, and development outsourcing</li>
        <li>Making business data more secure and easier to access</li>
        <li>Providing the best service to our customers that value for their money.</li>
      </ul>
      </span>
      </div>
      {/* <OurTeam /> */}
      <div className="spacing">
      {/* <Brandlogo /> */}
      </div>
    </>
  );
}
