import React, { useState  } from "react";
import { Row, Col, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormControl } from "react-bootstrap";
import icon from "../../../assets/Images/BrandLogo/icon.png";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'


export default function StartedToday() {

const navigate = useNavigate();
const [validationError,setValidationError] = useState({})
const [state, setState] = React.useState({
 email: ''
});

const handleChange = (event) => {

  setState({ ...state, [event.target.name]: event.target.value });
};

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

const SaveMessage = async (e) => {
  e.preventDefault();
  if (!isValidEmail(state.email)) {
      Swal.fire({
        confirmButtonColor:"#73def9",
        icon:"error",
        text:"Invalid email address"
      })
    } else {
      const formData = new FormData()
      formData.append('Email', state.email)


      const temp_data = {};
      formData.forEach((value, key) => (temp_data[key] = value));

      await axios.post('/web-api/public/api/v1/subscribe', temp_data).then(({data})=>{
        Swal.fire({
          confirmButtonColor:"#73def9",
          icon:data.icon,
          text:data.message
        })
        setState({
          email:''
        })
      }).catch(({response})=>{
        if(response.status===422){
          setValidationError(response.data.errors)
        }else{
          Swal.fire({
            confirmButtonColor:"#73def9",
            text:response.data.message,
            icon:"error"
          })
        }
      })
    }
}


  return (
    <>
      <div className="startedToday">
        <div className="container">
          <div className="startedTodayMainDivFloat">
            <Row>
              <Col xl={3} lg={1} md={1} sm={1}>
                <div className="startedTodayMainDiv">
                  <img
                    className="img-fluid mx-auto "
                    alt="AboutUs"
                    src={icon}
                  />
                  <div className="">
                    <span>SIGN UP FOR NEWSLETTER</span>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4} className="startedTodaySideTextCol">
                <div className="startedTodaySideText">
                  <p>
                    Subscribe To Our Newsletter For All The Latest
                    Updates
                  </p>
                </div>
              </Col>
              <Col xl={5} lg={7} md={7} sm={7} >
                <div className="startedEmailBox">
                  <InputGroup className="">
                    <FormControl placeholder="Your Email..." 
                                          type="text"
                                          className="form-control"
                                          id="email"
                                          name="email"
                                          onChange={handleChange}
                                          value={state.email}
                                          required
                    />
                    <button className="button" onClick={SaveMessage}>
                       Subscribe Now
                    </button>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
